.followUsWrapper {
  position: relative;
  width: 100%;
}

.followUsIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--tertiary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontsize-xxl);
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.followUsTitle {
  margin-bottom: var(--gutter-m);
}

.followUsIcon:hover {
  background-color: var(--secondary-color);
}

.followUsIconsWrapper {
  display: flex;
  gap: var(--gutter-l);
  position: absolute;
  bottom: calc((40px / 2) * -1);
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .followUsWrapper {
    height: fit-content;
  }

  .followUsTitle {
    font-size: var(--fontsize-s);
  }
}
