.placeInfosContainer {
  width: calc(80% - (var(--gutter-xl) * 2));
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  gap: var(--gutter-l);
}
.placeInfosWrapper {
  display: flex;
  padding: var(--gutter-l);
  gap: var(--gutter-l);
  color: var(--textcolor-alt);
  width: 88%;
  background-color: var(--primary-color);
  height: 100%;
  min-height: 185px;
  border-radius: var(--borderRadius-m);
  justify-content: space-between;
}

.placeInfoNoCrit {
  display: flex;
}

.placeInfosWrapper h1 {
  font-size: var(--fontsize-xxl);
  margin-bottom: var(--gutter-l);
}

.placeInfosCritizr {
  background-color: white;
  padding: var(--gutter-m);
  border-radius: var(--borderRadius-m);
  height: fit-content;
}

.placeInfosCritizrWrapper {
  margin-bottom: 60px;
}

.placeInfosIcon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fontsize-m);
  gap: 20px;
  color: white;
}

.flexGap {
  display: flex;
  gap: var(--gutter-m);
}

.flexGapColumn {
  display: flex;
  gap: var(--gutter-m);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.placeInfosMiddle p {
  margin-bottom: var(--gutter-m);
}

.placeInfosAddress {
  margin-top: 5px;
  font-style: normal;
}

.placeInfosModular {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.placeInfosRichFields {
  position: absolute;
  bottom: 0;
  right: 0;
}

.placeInfosButtonsWrapper {
  margin-left: var(--gutter-xl);
  gap: var(--gutter-xl);
  width: 50%;
}

.placeInfosDatas {
  flex-grow: 1;
}

.phoneButton {
  font-size: var(--fontsize-xs) !important;
  background-color: var(--tertiary-color);
  color: white;
}

.itinaryButton {
  background-color: var(--tertiary-color);
  font-size: var(--fontsize-xs) !important;
  color: white;
}

@media screen and (max-width: 1200px) {
  .phoneButton {
    font-size: var(--fontsize-xxs) !important;
    min-height: 14px !important;
    height: fit-content;
  }

  .itinaryButton {
    font-size: 35% !important;
    height: fit-content !important;
    min-height: 14px !important;
  }

  .placeInfosWrapper {
    margin-top: 0;
    width: calc(100% - (var(--gutter-l) * 2));
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
  }

  .placeInfoNoCrit {
    flex-direction: row-reverse;
  }

  .placeInfosMiddle {
    flex-direction: column-reverse;
  }

  .placeInfosWrapper h1 {
    font-size: var(--fontsize-xl);
  }

  .placeInfosButtonsWrapper {
    width: 100%;
    flex-direction: row-reverse;
    gap: var(--gutter-l);
    margin: 0;
    padding: var(--gutter-l) var(--gutter-l) var(--gutter-xl) var(--gutter-l);
    background-color: var(--primary-color);
    border-radius: 0 0 5px 5px;
  }

  .placeInfosContainer {
    width: 90%;
    gap: 0;
    margin-top: -30px;
  }

  .placeInfosAddress {
    width: 100%;
  }

  .placeInfosRichFields {
    position: relative;
    margin-top: calc(var(--gutter-l) * -1);
  }
  
  .placeInfosCritizrWrapper {
    margin-bottom: 0;
  }
}
