.appLinksWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 5px;
}

@media screen and (max-width: 1200px) {
  .appLinksWrapper {
    justify-content: center;
  }
}
