:root {
  --primary-color: #48bcc6;
  --secondary-color: #ec608f;
  --tertiary-color: #f6b8ce;
  --textcolor-alt: #f8f4ee;
  --borderPrimaryColor: #d9d9d9;
  --background-color: white;
  --text-color-inv: white;
  --gutter-s: 5px;
  --gutter-m: 10px;
  --gutter-l: 20px;
  --gutter-xl: 30px;
  --gutter-xxl: 40px;
  --fontsize-xxs: 9px;
  --fontsize-xs: 10px;
  --fontsize-s: 12px;
  --fontsize-m: 14px;
  --fontsize-l: 16px;
  --fontsize-xl: 20px;
  --fontsize-xxl: 24px;
  --wrapper-color-dark: #464545;
  --wrapper-color: #e3e3e3;
  --wrapper-color-m: white;
  --error-color: red;
  --success-color: rgb(59, 200, 57);
  --boxshadow-reg: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  --header-size: 105px;
  --aside-width: 230px;
  --iconsize-xxl: 50px;
  --iconsize-l: 30px;
  --mobile-cube-height: 130px;
  --borderRadius-m: 5px;
}

.sl-flex {
  display: flex;
}

.sl-flex-wrap {
  flex-wrap: wrap;
}

.sl-jc-center {
  justify-content: center;
}

.sl-jc-between {
  justify-content: space-between;
}

.sl-ai-self-center {
  align-self: center;
}

.sl-ai-center {
  align-items: center;
}

.sl-ai-start {
  align-items: flex-start;
}

.sl-ai-end {
  align-items: flex-end;
}

.sl-jc-start {
  justify-content: flex-start;
}

.sl-flex-col {
  flex-direction: column;
}

.sl-gap-s {
  gap: var(--gutter-s);
}

.sl-gap {
  gap: var(--gutter-m);
}

.sl-gap-l {
  gap: var(--gutter-l);
}

.sl-gap-xl {
  gap: var(--gutter-xl);
}

.sl-gap-xxl {
  gap: var(--gutter-xxl);
}

.sl-text-secondary {
  color: var(--img-dominant-color);
}

.sl-text-primary {
  color: var(--primary-color);
}

.sl-text-small {
  font-size: var(--fontsize-s);
}

.sl-text-m {
  font-size: var(--fontsize-m);
}

.sl-text-l {
  font-size: var(--fontsize-l);
}

.sl-text-smaller {
  font-size: 0.8em;
}

.sl-text-bold {
  font-weight: bold;
}

.sl-w-100 {
  width: 100%;
}

.sl-h-100 {
  height: 100%;
}

.sl-text-error {
  color: var(--error-color);
}

.sl-text-success {
  color: var(--success-color);
}

.sl-bg-red {
  background-color: red;
}

.sl-bg-blue {
  background-color: blue;
}

.sl-text-center {
  text-align: center;
}

.sl-flex-1 {
  flex: 1;
}