.joinUsWrapper {
  position: relative;
  width: 100%;
  background-color: var(--secondary-color);
  border-radius: var(--boderRadius-m);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--gutter-l);
  padding-bottom: var(--gutter-l);
  margin-bottom: var(--gutter-s);
}

.appointmentSmall {
  color: white;
  width: 70% !important;
  display: flex;
  position: absolute;
  top: 15px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: var(--borderRadius-m);
  padding: var(--gutter-m);
  font-size: var(--fontsize-s);
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .joinUsWrapper {
    background-color: var(--primary-color);
    position: inherit;
    width: 50%;
    height: var(--mobile-cube-height);
    padding: 0;
  }

  .appointmentSmall {
    background-color: inherit;
    position: inherit;
    width: 100% !important;
    font-size: inherit;
    padding: 0;
    text-align: center;
  }
}
