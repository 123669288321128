.servicesWrapper {
  width: 100%;
  color: var(--textcolor-alt);
  margin-top: calc(var(--gutter-l) * -1);
  background-color: var(--primary-color);
}

.servicesWrapper ul {
  background-color: var(--primary-color);
  padding: var(--gutter-l) var(--gutter-m) var(--gutter-l) var(--gutter-m);
  list-style: disc;
  font-size: var(--fontsize-m);
}

.servicesWrapper li {
  margin-top: var(--gutter-m);
  margin-left: var(--gutter-l);
}

.servicesWrapper h4 {
  margin-left: var(--gutter-m);
}

@media screen and (max-width: 1200px) {
  .servicesWrapper li {
    font-size: var(--fontsize-s);
    margin: 0;
  }

  .servicesWrapper li:not(:first-child) {
    font-size: var(--fontsize-s);
    margin-top: var(--gutter-s);
  }

  .servicesWrapper {
    width: 100%;
    background-color: var(--tertiary-color);
    height: var(--mobile-cube-height);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .servicesWrapper h4 {
    margin: 0;
  }
}
