.Footer {
  width: 100vw;
  margin-top: 80px;
}

.Footer .footer__nav {
  font-weight: 500;
}

.searchShortcutsCustomWrapper {
  margin-top: var(--gutter-m);
  margin-bottom: var(--gutter-l);
  width: 53%;
}

.searchShortcutsCustomList:not(:first-child) {
  margin-top: var(--gutter-m);
}

.searchShortcutsCustomList::before {
  content: "\000A0•\000A0";
  color: black;
}

.searchShortcutsCustomLink:hover {
  text-decoration: underline;
}

.searchShortcutsCustomListWrapper {
  columns: 2;
}

.searchShortcutsCustomTitle {
  font-size: var(--fontsize-m);
  font-weight: 500;
  color: black;
  text-transform: uppercase;
  margin-bottom: var(--gutter-m);
}

.searchShortcutsCustomLink {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 14px;
  vertical-align: baseline;
  text-decoration: none;
  font-weight: 500;
}

.tchipLinks {
  display: flex;
  align-items: flex-start;
}

.footer__logo {
  display: block !important;
  margin: 0 !important;
  align-self: flex-start !important;
}

.linksAll {
  width: 80%;
}

.footer__nav {
  width: 80% !important;
}

.footer__logo .logo {
  margin-right: 50px;
}

@media screen and (min-width: 1280px) {
}

@media screen and (max-width: 768px) {
  .tchipLinks {
    flex-direction: column;
    align-items: center;
  }

  .searchShortcutsCustomWrapper {
    margin-bottom: var(--gutter-m);
    width: 93%;
  }

  .footer__nav__item:first-child {
    padding-top: 0.6em !important;
  }

  .grid {
    display: flex !important;
    align-self: center !important;
    flex-direction: column !important;
  }

  .footer__logo {
    align-self: center !important;
    margin: 0 !important;
    width: inherit;
  }

  .footer__logo .logo {
    margin: 0 !important;
  }

  .footer__nav {
    width: 100% !important;
  }

  .linksAll {
    width: 100%;
  }
}
