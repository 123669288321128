@import "./variables.css";

@font-face {
  font-family: "Gotham";
  font-weight: normal;
  src: url("./fonts/Gotham/Gotham-Book.otf") format("opentype");
}

@font-face {
  font-family: "Gotham";
  font-weight: bold;
  src: url("./fonts/Gotham/Gotham-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Gotham_1";
  font-weight: normal;
  src: url("./fonts/Gotham_1/Gotham-Light.otf") format("opentype");
}

@font-face {
  font-family: "Gotham_1";
  font-weight: bold;
  src: url("./fonts/Gotham_1/Gotham-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: url("./fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: url("./fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/Poppins/Poppins-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Morganite";
  font-weight: normal;
  src: url("./fonts/Morganite/Morganite-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Morganite";
  font-weight: bold;
  src: url("./fonts/Morganite/Morganite-Bold.ttf") format("truetype");
}

* {
  font-family: "Poppins";
  box-sizing: content-box;
}

h1 {
  font-family: "Gotham_1";
}

h2 {
  font-family: "Gotham_1";
  font-weight: bold;
}

a {
  color: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}
