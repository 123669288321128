.openingHoursWrapper {
  background-color: var(--primary-color);
  color: var(--textcolor-alt);
  padding: var(--gutter-l);
  position: relative;
}

.openingHoursWrapper h2 {
  margin-bottom: var(--gutter-m);
}

.openingHoursWrapper hr {
  margin-top: var(--gutter-m);
  margin-top: var(--gutter-m);
}

.showHours {
  cursor: pointer;
  font-size: var(--fontsize-s);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dayWrapper {
  font-size: var(--fontsize-s);
}

.hourWrapper {
  color: white !important;
}

.hourDisplayerWrapper {
  position: absolute;
  background-color: black;
  padding: var(--gutter-l);
  left: 0;
  width: 250px;
  animation: popInAnim 0.3s;
  z-index: 1;
}

@keyframes popInAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .openingHoursWrapper {
    width: calc(90% - (var(--gutter-l) * 2));
  }

  .hourDisplayerWrapper {
    width: calc(100% - (var(--gutter-l) * 2));
  }
}
