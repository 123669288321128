.simpleCardWrapper {
  background-color: var(--primary-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--borderRadius-m) ;
}

.simpleCardWrapperContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: var(--gutter-l) var(--gutter-m) var(--gutter-l) var(--gutter-m);
  color: white;
  font-size: var(--fontsize-l);
}

.simpleCardWrapper a {
  color: white;
}

.simpleCardStrong {
  color: white;
  font-weight: bold;
}

.simpleCardWrapperIcon {
  font-size: var(--iconsize-xxl);
  margin-bottom: var(--gutter-xl);
}

.simpleCardTitle {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .simpleCardWrapperIcon {
    font-size: var(--iconsize-l);
    margin-bottom: var(--gutter-xl);
  }
  .simpleCardWrapper {
    height: fit-content;
  }
}
