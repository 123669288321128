.nearbyPlacesWrapperSection {
  background-color: var(--secondary-color);
  width: 100%;
  position: relative;
  border-radius: var(--borderRadius-m);
}

.nearbyPlacesWrapperContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--gutter-l) var(--gutter-m) var(--gutter-l) var(--gutter-m);
  color: white;
  font-size: var(--fontsize-l);
}

.nearbyPlacesWrapperContainer h4 {
  margin: 0 var(--gutter-m) 0 var(--gutter-m);
  font-size: var(--fontsize-m);
}

.nearbyPlacesDetailsWrapper a {
  color: white;
  font-size: var(--fontsize-s);
}

.nearbyPlacesDetailsWrapper li:not(:first-child) {
  margin-top: var(--gutter-l);
}

.nearbyPlacesCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nearbyPlacesDetailsWrapper {
  background-color: var(--secondary-color);
  padding: var(--gutter-l);
  width: 150px;
  animation: popInAnim 0.3s;
  z-index: 1;
  color: white;
  border-radius: var(--borderRadius-m);
}

.nearbyPlacesDetailsName {
  margin-bottom: var(--gutter-s);
  font-weight: bold;
}

@keyframes popInAnim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .nearbyPlacesWrapperSection {
    background-color: var(--primary-color);
    width: 90%;
    position: relative;
  }

  .nearbyPlacesDetailsWrapper {
    background-color: var(--primary-color);
    padding: var(--gutter-s);
    padding-bottom: 30px;
  }

  .nearByPlacesDotIcon {
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--tertiary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fontsize-xxl);
    color: white;
    cursor: pointer;
    transition: 0.3s;
    z-index: 2;
  }

  .nearbyPlacesWrapperContainer h4 {
    font-size: var(--fontsize-s);
  }
}
