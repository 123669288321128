.merchantListWrapper {
  width: 100%;
  max-width: 1290px;
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--gutter-xl);
}

.merchantListArticleWrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  height: 692px;
  margin-bottom: 50px;
}

.merchantListMerchantComponentContainer {
  height: 90%;
  width: 60%;
}

.merchantListMerchantComponentContainer h2 {
  color: var(--secondary-color);
}

.merchantListMerchantComponentWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gutter-xl);
  overflow-y: auto;
  height: 100%;
}

.merchantListMapComponentWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 692px;
}

.merchantCardWrapper {
  display: flex;
  justify-content: flex-start;
  gap: var(--gutter-l);
  flex-wrap: wrap;
}

.merchantCardImageDistance {
  position: absolute;
  top: 30px;
  left: 0;
  background-color: var(--secondary-color);
  padding: var(--gutter-m);
  font-size: var(--fontsize-s);
  color: white;
}

.merchantCardImageWrapper {
  width: 310px;
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.merchantCardImageWrapper img {
  width: 100%;
}

.merchantCardInfosWrapper {
  display: flex;
  gap: var(--gutter-l);
}

.merchantCardInfosText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--gutter-l);
}

.merchantCardInfosText h2 {
  font-size: var(--fontsize-xl);
  font-weight: normal;
}

.merchantCardInfosText a {
  color: black;
}

.merchantCardInfosNumberWrapper {
  padding-top: calc(var(--gutter-l) - 6px);
}

.merchantCardInfosNumberTag {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  font-size: var(--fontsize-s);
  background-color: #efed7e;
  color: black;
  font-weight: bold;
}

.merchantCardInfosNumberBar {
  width: 1px;
  height: 70%;
  margin-top: var(--gutter-s);
  background-color: var(--primary-color);
}

.merchantCardInfosNumberBarWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.merchantCardCtas {
  padding: var(--gutter-l);
  margin-left: auto;
}

.merchantCardDistance {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  margin-left: auto;
  margin-right: var(--gutter-l);
}

.merchantCardWaitingList {
  color: white;
  padding: var(--gutter-m);
  border-radius: 10px;
  background-color: var(--secondary-color);
  width: 100%;
}

@media screen and (max-width: 1290px) {
  .merchantListWrapper {
    width: 90%;
  }

  .merchantListArticleWrapper {
    flex-direction: column;
    height: inherit;
  }

  .merchantListMerchantComponentContainer {
    height: 100%;
    width: 100%;
    margin-top: var(--gutter-xl);
  }

  .merchantListMapComponentWrapper {
    height: 350px;
  }

  .merchantCardInfosText {
    flex-grow: 1;
  }

  .merchantCardWaitingList {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .merchantCardImageWrapper {
    width: 100%;
  }
}
