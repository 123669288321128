.homeSearchSearchBarContainer {
  max-width: 1290px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 0 var(--gutter-l);
}

.homeSearchTotalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.homeSearchOurSaloon {
  font-size: 65px;
  color: white;
  font-weight: bold;
}

.homeSearchFAP {
  font-weight: bold;
  border: 3px solid white;
  padding: var(--gutter-s) var(--gutter-xl) var(--gutter-s) var(--gutter-xl);
  color: white;
  font-size: var(--fontsize-m);
  width: fit-content;
}

.homeSearchContainer {
  width: 100%;
  background-color: #efed7e;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 26.8vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("https://placeloop-media.s3.amazonaws.com/brands/127243036029/contentportal/items/75718338303173--orig");
}

.homeSearchHeaderWrapper {
  width: 100%;
  max-width: 1290px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 400px;
  margin-top: 50px;
}

.homeSearchSearchBarWrapper {
  background-color: #e6007e;
  width: calc(100% - (50px * 2));
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  gap: var(--gutter-xl);
  margin-top: -150px;
}

.homeSearchSearchBarWrapper h1 {
  font-size: 30px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.homeSearchTitleBold {
  font-weight: bold;
}

.homeSearchSearchBarTools {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  gap: var(--gutter-xl);
  flex-wrap: wrap;
}

.homeSearchSearchMapS {
  box-sizing: content-box;
  min-width: 300px;
  width: 50%;
  box-shadow: 0 0 10px rgb(79 185 177 / 20%);
}

@media screen and (max-width: 1290px) {
  .homeSearchSearchBarContainer {
    width: 90%;
  }

  .homeSearchSearchBarWrapper {
    width: calc(100% - (20px * 2));
    padding: 20px;
    margin-top: -90px;
  }

  .homeSearchOurSaloon {
    font-size: 38px;
  }

  .homeSearchHeaderWrapper {
    width: 90%;
    height: 300px;
  }

  .homeSearchSearchBarTools {
    width: auto;
    justify-content: center;
  }
}
