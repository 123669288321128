.localPageHeaderWrapper {
  background-color: white;
  min-height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #eee;
}

.componentWrapper {
  background-color: white;
  padding: var(--gutter-l);
  display: flex;
  position: relative;
}

.componentWrapper:not(:last-child) {
  margin-bottom: var(--gutter-l);
}

.componentWrapper p {
  font-size: 12px;
  text-align: justify;
}

.componentWrapper h3 {
  margin-bottom: var(--gutter-m);
  font-size: 16px;
  display: flex;
  align-items: center;
}

.componentWrapper h3 i {
  margin-right: var(--gutter-m);
}

.componentWrapperFlex {
  flex-direction: column;
  position: relative;
}

.componentItemWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.componentItemWrapper:first-child {
  margin-right: var(--gutter-l);
}

.componentSubItem {
  display: flex;
  width: 100%;
  margin-bottom: var(--gutter-m);
}

.componentSubItem i {
  margin-right: var(--gutter-m);
}

.componentItemWrapperTitle {
  width: 100%;
  font-size: var(--fontsize-s);
}

.localPageHeader {
  background-image: url("https://placeloop-media.s3.amazonaws.com/brands/127243036029/contentportal/items/4316916845926--orig-preview");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 250px;
}

.localPageCenter {
  display: flex;
  gap: var(--gutter-xxl);
  max-width: 910px;
  flex-direction: column;
}

.localPageContent {
  display: flex;
  justify-content: space-between;
}

aside {
  min-width: var(--aside-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gutter-l);
}

.topInfosWrapper {
  display: flex;
  gap: var(--gutter-xl);
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.localPageWrapper {
  width: calc(100% - 40px);
  max-width: 1200px;
  display: flex;
  margin-bottom: 50px;
  margin-top: -50px;
  justify-content: space-between;
  gap: var(--gutter-l);
  flex-direction: column;
}

.localPageAppAndPricesMobile {
  display: flex;
  width: 90%;
  gap: var(--gutter-l);
}

.localPageHardCodedImage {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: var(--mobile-cube-height);
  border: 5px solid var(--primary-color);
  box-sizing: border-box;
}

.appointmentSmall {
  font-weight: normal;
}

.appointmentBig {
  font-weight: bold;
}

.appointmentWithout {
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: var(--gutter-s);
}

.descriptionWrapper {
}

.descriptionTitle {
  text-align: center;
  color: var(--primary-color);
  font-size: var(--fontsize-xxl);
  margin-top: var(--gutter-l);
}

.descriptionContent {
  white-space: pre-wrap;
  background-color: inherit;
  padding: var(--gutter-xl);
  font-size: var(--fontsize-m);
  line-height: 1.2rem;
}

.joinUsMobileWrapper {
  width: 90%;
  justify-content: center;
  display: flex;
  gap: var(--gutter-l);
  margin-top: -20px;
}

.joinUsMobileImage {
  width: 50%;
  background-color: var(--primary-color);
  height: var(--mobile-cube-height);
  overflow: hidden;
  box-sizing: border-box;
}

.joinUsMobileImage img {
  width: 100%;
  object-fit: contain;
}

.mobile-only {
  display: none;
}

.desktop-only {
}

.mobileInfosOnly {
  display: none;
}

@media screen and (max-width: 1200px) {
  .mobile-only {
    display: block;
  }

  .mobileInfosOnly {
    margin-top: var(--gutter-l);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gutter-l);
  }

  .desktop-only {
    display: none;
  }

  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--secondary-color);
  }

  .descriptionTitle {
    background-color: white;
    width: 100%;
    text-align: center;
    color: var(--primary-color);
    font-size: 16px;
    margin-top: 0;
  }

  .descriptionContent {
    color: var(--textcolor-alt);
    width: 90%;
    padding: var(--gutter-l);
  }

  .localPageWrapper {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
  }

  .localPageHeader {
    height: 135px;
  }

  .topInfosWrapper {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }

  .localPageCenter {
    width: 100%;
    align-items: center;
    gap: var(--gutter-l);
  }

  .appointmentWrapper {
    margin-top: var(--gutter-xl);
  }
}
